<template>
  <el-card id="niche">
    <header>
      <h2 style="margin-bottom: 20px">筛选<i class="el-icon-s-help"></i></h2>
      <div class="top-box">
        <div class="select-item">
          <span>选择跟进人</span>
          <el-select
            v-model="screenForm.userId"
            filterable
            clearable
            placeholder="按跟进人筛选"
            size="mini"
          >
            <el-option
              v-for="item in staffList"
              :key="item.user_id"
              :label="item.realname"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-item">
          <span>选择客户</span>
          <el-select
            v-model="screenForm.clentId"
            filterable
            clearable
            placeholder="按客户筛选"
            size="mini"
          >
            <el-option
              v-for="item in KHoptions"
              :key="item.id"
              :label="item.customerPhone"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-item">
          <span>客户名</span>
          <el-input
            v-model="screenForm.clentName"
            placeholder="输入完整客户名"
            clearable
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="addNiche"
              size="mini"
              class="searchBtn"
              >新增商机</el-button
            >
          </div>
        </div>
        <div class="right">
          <el-button
            type="success"
            icon="el-icon-search"
            @click="query"
            size="mini"
            >查询
          </el-button>
          <el-button
            type="success"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            >重置
          </el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <div class="table-box">
        <el-table
          :data="tableData"
          v-loading="tableLoad"
          element-loading-text="加载数据中..."
          border
          class="table"
          ref="multipleTable"
          @row-dblclick="dblclick"
          header-cell-class-name="table-header"
        >
          <!-- 多选按钮 -->
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>
          <!-- <el-table-column label="审批工单类型" align="center">
          <template #default="scope">
            <div>{{ setShowTypeName(scope.row.bsf) }}</div>
          </template>
        </el-table-column> -->
          <el-table-column
            v-for="(v, i) in dataLabel"
            :key="i"
            :label="v.label"
            :prop="v.name"
            align="center"
          ></el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="300"
            align="center"
          >
            <template #default="scope">
              <el-button
                type="success"
                @click="editNiche(scope.row)"
                size="mini"
                >编辑</el-button
              >
              <el-button type="danger" @click="delNiche(scope.row)" size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>

    <!-- 弹框 -->
    <el-dialog
      :title="setText + '商机'"
      :visible.sync="outerVisible"
      @close="closeDialog"
    >
      <el-form
        ref="form"
        label-position="right"
        size="medium"
        :model="form"
        label-width="100px"
      >
        <!-- 表单过多时使用下面这种布局 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="选择客户">
              <el-select
                v-model="form.clentName"
                filterable
                clearable
                :disabled="setText == '编辑' || setText == '查看'"
                placeholder="为意向客户创建商机"
              >
                <el-option
                  v-for="item in KHoptions"
                  :key="item.id"
                  :label="' ' + item.customerPhone"
                  :value="[item.id, item.customerPhone]"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商机阶段">
              <el-select
                v-model="form.shangjiStage"
                placeholder="请选择阶段"
                clearable
                :disabled="setText == '查看'"
              >
                <el-option
                  v-for="item in JDoptions"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label="选择客户">
          <el-select
            v-model="form.kh"
            filterable
            clearable
            placeholder="请选择客户"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商机阶段">
          <el-select v-model="form.businessStatus" placeholder="请选择阶段">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="商机金额">
          <el-input
            v-model="form.money"
            clearable
            type="number"
            placeholder="请输入商机金额"
            :disabled="setText == '查看'"
          ></el-input>
        </el-form-item>
        <el-form-item label="商机名称">
          <el-input
            v-model="form.name"
            placeholder="请设置商机名称"
            clearable
            :disabled="setText == '查看'"
          ></el-input>
        </el-form-item>

        <el-form-item label="预计成交日期">
          <el-date-picker
            v-model="form.predictMakeTime"
            type="datetime"
            clearable
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="setText == '查看'"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="form.remarke"
            maxlength="1000"
            show-word-limit
            :disabled="setText == '查看'"
            placeholder="商机相关备注"
            autosize
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="btn-box">
            <el-button @click="closeDialog">取消</el-button>
            <el-button type="primary" @click="onSubmit" v-if="setText != '查看'"
              >确认</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import { select, add, deleteNiche, update } from "@/api/niche";
import { phoneList } from "@/api/phone";
import { userList1 } from "@/api/user";
export default {
  data() {
    return {
      staffList: [],
      tableLoad: false, // 表格加载效果
      screenForm: {}, // 筛选表单
      KHoptions: [],
      JDoptions: [
        "初步沟通(10%)",
        "方案报价(50%)",
        "谈判协商(80%)",
        "赢单(100%)",
        "输单(0%)",
        "无效",
      ],
      setText: "",
      outerVisible: false,
      form: {},
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //总条数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //每页显示的数量
      page: 1, //第几页
      size: 10, //每页显示的数量
      dataLabel: [
        {
          label: "跟进人",
          name: "createUserName",
        },
        {
          label: "商机名称",
          name: "name",
        },
        {
          label: "客户名称",
          name: "clentName",
        },
        {
          label: "商机备注",
          name: "remarke",
        },
        {
          label: "销售阶段",
          name: "shangjiStage",
        },
        {
          label: "预计成交金额",
          name: "money",
        },
        {
          label: "预计成交日期",
          name: "predictMakeTime",
        },
      ],
    };
  },
  async created() {

    await this.getList();
    await this.getLntentionUser();
    await this.getAllStaff();
  },
  methods: {
    // 查询
    query() {
      this.getList();
    },
    // 获取所有员工
    async getAllStaff() {
      let res = await userList1({});
      console.log("获取所有员工", res.data.data);
      if (res.data.statusCode == "00000") {
        this.staffList = res.data.data;
      }
    },
    // 重置
    reset() {
      this.page = 1;
      this.size = 10;
      this.screenForm = {};
      this.getList();
    },
    // 单个删除
    delNiche(row) {
      this.$confirm(
        `是否要删除客户 "${row.clentName}" 的该商机信息?`,
        "删除商机",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          let params = {
            id: row.id,
          };
          let res = await deleteNiche(params);
          if (res.data.statusCode == "00000") {
            await this.closeDialog();
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 手动验证方法
    verification1() {
      if (!this.form.clentName) {
        Message.warning("请选择客户");
        return false;
      } else {
        return true;
      }
    },
    // 新增或修改的提交
    async onSubmit() {
      let bool = await this.verification1();
      if (!bool) {
        return;
      }
      const loading = await this.$loading({
        lock: true,
        text: this.setText + "中,请稍等...",
      });
      let params;
      let res;
      if (this.setText == "新增") {
        params = {
          clentId: this.form.clentName[0],
          clentName: this.form.clentName[1],
          money: this.form.money,
          name: this.form.name,
          predictMakeTime: this.form.predictMakeTime,
          remarke: this.form.remarke,
          shangjiStage: this.form.shangjiStage,
        };
        res = await add(params);
      } else if (this.setText == "编辑") {
        params = {
          ...this.form,
        };
        res = await update(params);
      }

      if (res.data.statusCode == "00000") {
        await this.closeDialog();
        Message.success(res.data.message);
        loading.close();
      } else {
        Message.error(res.data.message);
        loading.close();
      }
    },
    // 双击单行查看详情
    dblclick(row) {
      this.form = row;
      this.setText = "查看";
      this.outerVisible = true;
    },
    // 获取所有意向客户
    async getLntentionUser() {
      let params = {
        bsf: 1,
        page: 1,
        size: 5000,
      };
      let res = await phoneList(params);
      this.KHoptions = res.data.data;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 编辑商机
    editNiche(row) {
      console.log("row", row);
      this.form = row;
      this.setText = "编辑";
      this.outerVisible = true;
    },
    // 新增商机
    addNiche() {
      this.setText = "新增";
      this.outerVisible = true;
    },
    // 关闭弹框
    closeDialog() {
      this.form = {};
      this.outerVisible = false;
      this.getList();
    },
    // 获取表格数据
    async getList() {
      this.tableLoad = true;
      let params = {
        page: this.page,
        size: this.size,
        ...this.screenForm,
      };
      let res = await select(params);
      this.pageTotal = res.data.total;
      this.tableData = res.data.data;
      this.tableLoad = false;
      // statusCode  合计金额
      console.log("返回参数", res.data);
      Message.success("已刷新数据");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;
  &:hover {
    opacity: 0.7;
  }
}

#niche {
  .top-box {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    // line-height: 30px;
    margin-bottom: 40px;
    /deep/ .el-select{
      width: 100%;
    }
    .select-item {
      width: 20%;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        min-width: 70px;
        text-align: right;
        align-items: center;
        font-size: 14px;
      }
    }
  }
  .search {
    display: flex;
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      line-height: 30px;
      .left-wrap {
        display: flex;
        align-items: center;
      }
    }
    .right {
      width: 50%;
      text-align: right;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }
  /deep/ .el-table {
    // max-height: 555px;
    // padding-left: 50px;
    // box-sizing: border-box;
    // overflow-y: scroll;
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .el-select,
      .el-input {
        width: 100%;
      }
      .el-textarea__inner {
        min-height: 100px !important;
      }
      .el-dialog__header {
        text-align: center;
        .el-dialog__title {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .el-dialog__body {
        position: relative;
        min-height: 500px;
        .el-form {
        }
        .el-form-item__content {
          // 修改样式会混乱
          .btn-box {
            text-align: right;
          }
        }
      }
    }
  }
}
main {
  .table-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 480px;
    overflow-y: scroll;
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    scrollbar-width: none;
  }
  .table-box::-webkit-scrollbar {
    display: none;
  }
  .pagination {
    text-align: right;
    margin: 20px 0;
  }
}
</style>